import React from "react";

const TableHeader = ({ sortBy, sortDirection, onSort }) => {
  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return sortDirection === "asc" ? "↑" : "↓";
    }
    return "";
  };

  return (
    <thead className="bg-black bg-opacity-100 text-gray-400 uppercase text-sm sticky top-0 z-10">
      <tr>
        {/* Blank header for skull icon */}
        <th className="py-3 px-4 w-10"></th>
        {/* Sortable columns */}
        <th
          className="py-3 px-6 cursor-pointer"
          //onClick={() => onSort("rank")}
        >
          Rank {renderSortIcon("rank")}
        </th>
        <th
          className="py-3 px-6 cursor-pointer"
         // onClick={() => onSort("character")}
        >
          Character {renderSortIcon("character")}
        </th>
        <th className="py-3 px-6">Account</th>
        <th
          className="py-3 px-6 cursor-pointer"
         // onClick={() => onSort("league")}
        >
          League {renderSortIcon("league")}
        </th>
        <th
          className="py-3 px-6 cursor-pointer"
          //onClick={() => onSort("class")}
        >
          Class {renderSortIcon("class")}
        </th>

                <th
          className="py-3 px-6 cursor-pointer"
         // onClick={() => onSort("level")}
        >
          Level {renderSortIcon("level")}
        </th>
        <th className="py-3 px-6">Progress</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
