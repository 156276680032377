import React, { useState } from "react";
import achievements from "../data/achievements.json";
import thechamp from "../data/thechamp.json";

const Achievements = () => {
  const [activeTab, setActiveTab] = useState("the champ");

  const progressData = [
    { category: "General", completed: 27, total: 30 },
    { category: "Player vs. Player", completed: 15, total: 28 },
    { category: "Professions", completed: 22, total: 22 },
    { category: "Quests", completed: 7, total: 7 },
    { category: "Dungeons & Raids", completed: 113, total: 168 },
    { category: "Reputation", completed: 7, total: 7 },
  ];

  // Define content for each tab
   const tabContent = {
  /*   summary: (
      <>
        {}
        <div>
          <h2 className="text-xl font-bold mb-2 text-secondary">Recent Achievements</h2>
          <ul className="space-y-2">
            {achievements.map((achievement, index) => (
              <li
                key={index}
                className="flex items-center p-4 bg-overlayBlack rounded shadow"
              >
                <div className="text-2xl mr-4">{achievement.icon}</div>
                <div>
                  <h3 className="text-lg font-semibold">{achievement.title}</h3>
                  <p className="text-sm text-tableText">
                    {achievement.description}
                  </p>
                  <p className="text-sm text-gray-400">{achievement.date}</p>
                </div>
                <div className="ml-auto text-secondary font-bold">
                  {achievement.points} Points
                </div>
              </li>
            ))}
          </ul>
        </div> 
        {}
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4 text-secondary">Progress Overview</h2>
          {progressData.map((progress, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between text-sm mb-1">
                <span className="text-primary">{progress.category}</span>
                <span className="text-primary">
                  {progress.completed}/{progress.total}
                </span>
              </div>
              <div className="w-full bg-tableBackground rounded-full h-4">
                <div
                  className="bg-secondary h-4 rounded-full"
                  style={{
                    width: `${
                      (progress.completed / progress.total) * 100
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </>
    ), */
    "the champ": (
      <>
        {/* The Champ Tab */}
        <div>
          <h2 className="text-xl font-bold mb-2 text-secondary">The Champ</h2>
          <ul className="space-y-2">
            {thechamp.map((champ, index) => (
              <li
                key={index}
                className="flex items-center p-4 bg-overlayBlack rounded shadow"
              >
                <div className="text-2xl mr-4">{champ.icon}</div>
                <div>
                  <h3 className="text-lg font-semibold">{champ.title}</h3>
                  <p className="text-sm text-tableText">
                    {champ.description}
                  </p>
                </div>
                <div className="ml-auto text-secondary font-bold">
                  {champ.date}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    quests: <p className="text-primary">Quest achievements content goes here.</p>,
    "player vs. player": (
      <p className="text-primary">Player vs. Player achievements content goes here.</p>
    ),
    "dungeons & raids": (
      <p className="text-primary">Dungeons & Raids achievements content goes here.</p>
    ),
    professions: <p className="text-primary">Professions achievements content goes here.</p>,
    reputation: <p className="text-primary">Reputation achievements content goes here.</p>,
  };

  return (
    <div className="container mx-auto p-4 bg-transparentBlack text-primary rounded-lg">
      {/* Tabs */}
      <div className="flex space-x-4 border-b border-overlayBlack mb-4">
        {[
          /*"Summary",*/
          "The Champ",
          /*"Quests",
          "Player vs. Player",
          "Dungeons & Raids",
          "Professions",
          "Reputation",*/
        ].map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 ${
              activeTab === tab.toLowerCase()
                ? "border-b-2 border-secondary text-secondary"
                : "text-tableText"
            }`}
            onClick={() => setActiveTab(tab.toLowerCase())}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="p-4 bg-overlayBlack rounded shadow">
        {tabContent[activeTab] || (
          <p className="text-primary">No content available for this tab.</p>
        )}
      </div>
    </div>
  );
};

export default Achievements;
