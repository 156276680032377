import React from "react";

const Standings = () => {
  // Example data
  const currentChamp = {
    name: "myhoax",
    league: "Settlers",
  };

  const previousChamps = [
    { name: "Shockthecat", league: "Necropolis" },
    { name: "MarcSR", league:  "Trial of the Ancestor" },
  ];

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold text-center text-white mb-4">Standings</h2>

      {/* Current Champ */}
      <div className="text-center mb-8">
        <h3 className="text-2xl font-semibold text-secondary mb-2">The Current Champ</h3>
        <p className="text-xl text-primary">{currentChamp.name}</p>
        <p className="text-gray-300">{currentChamp.league}</p>
      </div>

      {/* Previous Champs */}
      <div>
        <h3 className="text-xl font-semibold text-secondary mb-4 text-center">Previous Champs</h3>
        <ul className="space-y-2">
          {previousChamps.map((champ, index) => (
            <li
              key={index}
              className="flex justify-between items-center bg-transparentBlack p-4 rounded shadow"
            >
              <span className="text-primary">{champ.name}</span>
              <span className="text-gray-400">{champ.league}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Standings;
