import React from "react";

const Footer = () => {
  return (
    <footer className="bg-transparentBlack backdrop-blur-md text-primary py-4 absolute bottom-0 w-full">
      <div className="text-center">
        {/* External Links */}
        <nav className="mb-4 flex justify-center space-x-6">
          <a
            href="https://www.pathofexile.com/forum/view-thread/3590682"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-secondary transition duration-300"
          >
            Click to join
          </a>
        </nav>
        <p className="text-sm text-tableText">
          © {new Date().getFullYear()} ACE Guild. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
