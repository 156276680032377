import React from "react";

const TableBody = ({ players, calculateProgress }) => {
  return (
    <tbody>
      {players.map((player, index) => (
        <tr
          key={`${player.character_name}-${index}`} // Ensure unique key
          className={`${
            player.dead ? "text-gray-500" : "text-white"
          } hover:bg-white hover:bg-opacity-10 transition duration-300`}
        >
          {/* Skull Icon */}
          <td className="py-3 px-4 border-t border-gray-600 text-center">
            {player.dead ? <span className="text-red-500">💀</span> : ""}
          </td>

          <td className="py-3 px-6 border-t border-gray-600">{player.rank}</td>
          <td className="py-3 px-6 border-t border-gray-600">{player.character_name}</td>
          <td className="py-3 px-6 border-t border-gray-600">{player.account_name}</td>
          <td className="py-3 px-6 border-t border-gray-600">{player.league_id}</td>
          <td className="py-3 px-6 border-t border-gray-600">{player.class}</td>
          <td className="py-3 px-6 border-t border-gray-600">{player.level}</td>
          <td className="py-3 px-6 border-t border-gray-600 relative group">
            {/* Progress Bar */}
            <div className="relative w-full bg-gray-600 h-2 rounded">
              <div
                className="absolute top-0 left-0 h-full bg-gradient-to-r from-green-400 to-blue-500 rounded"
                style={{ width: `${calculateProgress(player.level, player.experience)}%` }}
              ></div>
            </div>

            {/* Tooltip on Hover */}
            <div className="absolute left-0 bottom-6 bg-transparentBlack text-primary text-sm px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition duration-300">
              Experience: {player.experience.toLocaleString()}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
