import React from "react";

const FAQ = () => {
  return (
    <div
      className="bg-cover bg-center min-h-screen py-12"
      style={{ fontFamily: "ace" }}
    >
      <div className="container mx-auto text-primary">

        {/* Guild Introduction */}
        <div className="text-gray-300 text-center mb-6">
          <p>
            <span className="text-secondary">Alpha Crucis Exiles</span>
          </p>
          <p>
            We are primarily an Oceania guild (via SEA/AU/NZ gateways) but also welcome non-Oceania
            members. Enjoy awesome gaming, Discord socials, and community events!
          </p>
        </div>

        {/* Guild Staff & Contacts */}
        <div className=" text-gray-300 p-4 rounded shadow-md mb-6">
          <h3 className="text-xl font-semibold mb-2">Guild Staff & Contacts</h3>
          <ul>
            <li>
              <span className="font-bold text-secondary">Kaysee#3180</span> (Guild Leader), IGN:
              Kaysee [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Kaysee%233180"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">Tdogg555#3695</span> (Guild Leader), IGN:
              Tdogg_RF [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Tdogg555%233695"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">Insanitis#5227</span> (Guild Leader), IGN:
              Insanitis [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Insanitis%235227"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">Freeney#5857</span> (Officer), IGN: Freeney [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Freeney%235857"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">maffa50#6063</span> (Officer), IGN:
              MaffaFaFa [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/maffa50%236063"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">Smaced#0483</span> (Officer), IGN: Smaced [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Smaced%230483"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
            <li>
              <span className="font-bold text-secondary">Myhoax#5664</span> (Officer), IGN: MyHoax [
              <a
                href="https://www.pathofexile.com/private-messages/compose/to/Myhoax%235664"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary underline"
              >
                PM
              </a>
              ]
            </li>
          </ul>
        </div>

        {/* Guild Benefits */}
        <div className="text-gray-300 p-4 rounded shadow-md mb-6">
          <h3 className="text-xl font-semibold mb-2">Benefits of Joining ACE</h3>
          <ul className="list-disc ml-6">
            <li>Play with experienced and knowledgeable PoE players.</li>
            <li>Friendly and helpful members both in-game and on Discord.</li>
            <li>Share highs and lows with mates.</li>
            <li>
              HUGE Guild Stash available at all stages of the game - including a RNGesus worship
              tab.
            </li>
            <li>Guild group play, rotations, map completions, and boss kills.</li>
            <li>A highly organized guild. ACE is always active!</li>
          </ul>
        </div>

        {/* Guild Policies */}
        <div className="text-gray-300 p-4 rounded shadow-md">
          <h3 className="text-xl font-semibold mb-2">Guild Policies</h3>
          <ul className="list-disc ml-6">
            <li>Acceptable Behavior: Don’t be a dick.</li>
            <li>Membership: Be ACE: Active, Considerate, and Engaged.</li>
            <li>Social: Help build gaming and social culture.</li>
            <li>
              Identity: Use a tag or your Account Name in your Character Names (helps for easy
              recognition).
            </li>
            <li>
              Moderation: ACE reserves the right to moderate or dismiss any member without
              explanation.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
