import React from 'react';

const News = () => {
  return (
    <div>
      <h2 className="text-3xl font-bold text-center text-white mb-4">News</h2>
      <p className="text-gray-300 text-center">Content for News will go here.</p>
    </div>
  );
};

export default News;
