import React, { useState, useEffect } from "react";
import levelsData from "../data/levels.json"; // Import the levels JSON
import mockData from "../data/output.json"; // Import the mock data
import Filters from "./Filters";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Leaderboard = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    Game: [],
    Seasion: [],
    leagues: [],
    classes: [],
  });
  const [filter, setFilter] = useState({
    Game: "1", // Default to Game 1
    Seasion: "",
    league: "",
    class: "",
    character: "",
  });

  useEffect(() => {
    const sortedData = [...mockData].sort((a, b) => a.rank - b.rank);
    setPlayers(sortedData); // Initialize players with sorted data
    
    const filteredLeagues = mockData
      .filter((p) => {
        const matchesGame = filter.Game ? p.game === parseInt(filter.Game) : true;
        const matchesSeason = filter.Seasion ? p.category_id === filter.Seasion : true;
        return matchesGame && matchesSeason;
      })
      .map((p) => p.league_id);
  
    const filteredSeasons = mockData
      .filter((p) => {
        const matchesGame = filter.Game ? p.game === parseInt(filter.Game) : true;
        return matchesGame;
      })
      .map((p) => p.category_id);
  
    const filteredClasses = mockData
      .filter((p) => {
        const matchesGame = filter.Game ? p.game === parseInt(filter.Game) : true;
        const matchesLeague = filter.league ? p.league_id === filter.league : true;
        const matchesSeason = filter.Seasion ? p.category_id === filter.Seasion : true;
        return matchesGame && matchesLeague && matchesSeason;
      })
      .map((p) => p.class);
  
    setFilterOptions({
      Game: [...new Set(mockData.map((p) => p.game))],
      leagues: [...new Set(filteredLeagues)],
      Seasion: [...new Set(filteredSeasons)],
      classes: [...new Set(filteredClasses)],
    });
  }, [filter.Game, filter.Seasion, filter.league]);
  
  

  // Handle Filters
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      // Reset all dependent filters when Game changes
      if (key === "Game") {
        return {
          Game: value, // Update Game
          league: "", // Reset league
          Seasion: "", // Reset season
          class: "", // Reset class
          character: "", // Reset character search
        };
      }
  
      // Reset dependent filters when Seasion changes
      if (key === "Seasion") {
        return {
          ...prev,
          [key]: value, // Update season
          league: "", // Reset league
          class: "", // Reset class
        };
      }
  
      // Reset dependent filters when League changes
      if (key === "league") {
        return {
          ...prev,
          [key]: value, // Update league
          class: "", // Reset class
        };
      }
  
      // For all other keys, just update the value
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  

  useEffect(() => {
    const filtered = players.filter((player) => {
      const matchesGame = filter.Game ? player.game === parseInt(filter.Game) : true;
      const matchesSeason = filter.Seasion ? player.category_id === filter.Seasion : true;
      const matchesLeague = filter.league ? player.league_id === filter.league : true;
      const matchesClass = filter.class ? player.class === filter.class : true;
      const matchesCharacterOrAccount = filter.character
        ? player.character_name.toLowerCase().includes(filter.character.toLowerCase()) ||
          player.account_name.toLowerCase().includes(filter.character.toLowerCase())
        : true;

      return matchesGame && matchesSeason && matchesLeague && matchesClass && matchesCharacterOrAccount;
    });
    setFilteredPlayers(filtered);
  }, [filter, players]);

  // Calculate Progress
  const calculateProgress = (level, experience) => {
    const levelData = levelsData.find((l) => l.level === level);
    if (!levelData) return 100; // Prevent undefined levelData errors
  
    const progress = ((experience - levelData.total_xp) / levelData.xp_to_gain) * 100;
    return Math.min(Math.max(progress, 0), 100); // Clamp progress between 0 and 100
  };
  

  return (
    <div className="py-6">
      <h2 className="text-3xl font-bold text-center text-primary mb-6">
        Leaderboard
      </h2>

      {/* Filters */}
      <Filters
  filter={filter}
  filterOptions={{
    Game: filterOptions.Game,
    leagues: filterOptions.leagues,
    Seasion: filterOptions.Seasion,
    classes: filterOptions.classes,
  }}
  onFilterChange={handleFilterChange}
/>


      {/* Scrollable Table */}
      <div className="overflow-x-auto shadow-md rounded-lg">
        <div className="max-h-[500px] overflow-y-auto">
          <table className="table-auto w-full text-left bg-tableBackground bg-opacity-90 text-tableText border-collapse">
            <TableHeader />
            <TableBody players={filteredPlayers}                 
            calculateProgress={(level, experience) =>
                  calculateProgress(level, experience)
                } />
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
