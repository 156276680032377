import React from "react";

const Filters = ({ filter, filterOptions, onFilterChange }) => {
  const gameDisplayMap = {
    1: "Poe 1",
    2: "Poe 2",
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
      {/* Game Filter */}
      <select
        className="bg-tableBackground bg-opacity-80 text-tableText p-2 rounded shadow"
        value={filter.Game}
        onChange={(e) => onFilterChange("Game", e.target.value)}
      >
        {filterOptions.Game.map((game) => (
          <option key={game} value={game}>
            {gameDisplayMap[game] || `Game ${game}`}
          </option>
        ))}
      </select>

      {/* Season Filter */}
      <select
        className="bg-tableBackground bg-opacity-80 text-tableText p-2 rounded shadow"
        value={filter.Seasion}
        onChange={(e) => onFilterChange("Seasion", e.target.value)}
      >
        <option value="">Select Season</option>
        {filterOptions.Seasion.map((season) => (
          <option key={season} value={season}>
            {season}
          </option>
        ))}
      </select>

            {/* League Filter */}
            <select
        className="bg-tableBackground bg-opacity-80 text-tableText p-2 rounded shadow"
        value={filter.league}
        onChange={(e) => onFilterChange("league", e.target.value)}
      >
        <option value="">Select League</option>
        {filterOptions.leagues.map((league) => (
          <option key={league} value={league}>
            {league}
          </option>
        ))}
      </select>

      {/* Class Filter */}
      <select
        className="bg-tableBackground bg-opacity-80 text-tableText p-2 rounded shadow"
        onChange={(e) => onFilterChange("class", e.target.value)}
      >
        <option value="">Filter by Class</option>
        {filterOptions.classes.map((classOption) => (
          <option key={classOption} value={classOption}>
            {classOption}
          </option>
        ))}
      </select>

      {/* Character Search */}
      <input
        type="text"
        placeholder="Search"
        className="bg-tableBackground bg-opacity-80 text-tableText p-2 rounded shadow"
        onChange={(e) => onFilterChange("character", e.target.value)}
      />
    </div>
  );
};

export default Filters;

 