import React, { useState, useEffect } from "react";

const AceLogo = () => {
  const [dynamicHeight, setDynamicHeight] = useState("100vh");

  useEffect(() => {
    const calculateHeight = () => {
      const navbarHeight = document.querySelector("nav")?.offsetHeight || 0;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      const availableHeight = `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`;
      setDynamicHeight(availableHeight);
    };

    // Calculate height initially
    calculateHeight();

    // Recalculate on window resize to handle dynamic screen sizes
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  return (
    <div
      style={{ height: dynamicHeight }}
      className="w-full flex items-center justify-center bg-aceBackground bg-cover bg-center bg-no-repeat relative"
    >
      {/* Dimming Overlay */}
      <div className="absolute inset-0 bg-overlayBlack"></div>

      {/* Logo */}
      <img
        src={`${process.env.PUBLIC_URL}/ace-white.png`}
        alt="ACE Guild Logo"
        className="relative z-10 max-w-[40%] max-h-[40%] object-contain"
      />
    </div>
  );
};

export default AceLogo;
