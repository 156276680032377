import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AceLogo from "./components/AceLogo"; // Default page
import Leaderboard from "./components/Leaderboard";
import Standings from "./components/Standings";
import Rips from "./components/Rips";
import FAQ from "./components/FAQ";
import News from "./components/News";
import Achievements from "./components/Achievements";

function App() {
  return (
    <Router>
      <div className="bg-aceBackground bg-cover bg-center bg-fixed text-primary min-h-screen flex flex-col">
        <div className="bg-transparentBlack flex flex-col flex-grow">
          {/* Navbar */}
          <Navbar />

          {/* Main Content */}
          <Routes>
            {/* Default Page */}
            <Route
              path="/"
              element={
                <main className="w-screen h-[calc(100vh-8rem)] flex items-center justify-center overflow-hidden">
                  <AceLogo />
                </main>
              }
            />

            {/* Other Pages */}
            <Route
              path="*"
              element={
                <main className="container mx-auto py-8 flex-grow">
                  <Routes>
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="/standings" element={<Standings />} />
                    <Route path="/rips" element={<Rips />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/Achievements" element={<Achievements />} />
                  </Routes>
                </main>
              }
            />
          </Routes>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
