import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-transparentBlack backdrop-blur-md text-primary shadow-lg sticky top-0 z-50 w-full">
      <div className="flex justify-between items-center px-6 py-4">
        {/* Left Section: Logo and Navigation Links */}
        <div className="flex items-center space-x-8">
          {/* Logo */}
          <NavLink to="/">
            <img
              src="/apple-touch-icon.png" // Replace with the actual path to your image
              alt="Home"
              className="h-8 w-8" // Adjust size as needed
            />
          </NavLink>

          {/* Navigation Links */}
          <div className="hidden md:flex items-center space-x-8">
            <NavLink
              to="/leaderboard"
              className={({ isActive }) =>
                `hover:text-secondary transition duration-300 ${
                  isActive ? "underline underline-offset-4" : ""
                }`
              }
            >
              Leaderboard
            </NavLink>
            <NavLink
              to="/achievements"
              className={({ isActive }) =>
                `hover:text-secondary transition duration-300 ${
                  isActive ? "underline underline-offset-4" : ""
                }`
              }
            >
              Achievements
            </NavLink>
            <NavLink
              to="/faq"
              className={({ isActive }) =>
                `hover:text-secondary transition duration-300 ${
                  isActive ? "underline underline-offset-4" : ""
                }`
              }
            >
              FAQ
            </NavLink>
          </div>
        </div>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden text-primary hover:text-secondary"
          onClick={toggleMenu}
          aria-label="Toggle navigation"
        >
          ☰
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-transparentBlack p-4 space-y-2">
          <NavLink
            to="/leaderboard"
            onClick={() => setIsOpen(false)}
            className={({ isActive }) =>
              `block hover:text-secondary transition duration-300 ${
                isActive ? "underline underline-offset-4" : ""
              }`
            }
          >
            Leaderboard
          </NavLink>
          <NavLink
            to="/achievements"
            onClick={() => setIsOpen(false)}
            className={({ isActive }) =>
              `block hover:text-secondary transition duration-300 ${
                isActive ? "underline underline-offset-4" : ""
              }`
            }
          >
            Achievements
          </NavLink>
          <NavLink
            to="/faq"
            onClick={() => setIsOpen(false)}
            className={({ isActive }) =>
              `block hover:text-secondary transition duration-300 ${
                isActive ? "underline underline-offset-4" : ""
              }`
            }
          >
            FAQ
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
